import React from "react"
import Layout from "../../components/modules/Layout"
import BodyBlocks from "../../components/technical/BodyBlocks"

const kickerPlaceholder = "Ein Platzhalter-Kicker"
const headlinePlaceholder = "Eine Platzhalter-Überschrift"
const subheadlinePlaceholder = "Eine Platzhalter-Unterzeile"
const textPlaceholder =
  "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße. Ich bin allein und freue mich meines Lebens in dieser Gegend, die für solche Seelen geschaffen ist wie die meine."
const buttonsPlaceholder = [
  {
    link_text: "Primär Button",
    link: "/",
  },
  {
    link_text: "Sekundär Button",
    link: "/",
  },
]
const imagePlaceholder = {
  filename:
    "https://a.storyblok.com/f/108683/1920x1080/d6600d2022/bg_placeholder_1080p_center.jpg",
}
const videoPlaceholder = "https://www.youtube.com/watch?v=8o-JI8VRyKo"

const decriptionPlaceholder = "Ich bin so glücklich, mein Bester..."

const ExtendedPage = () => {
  const body = [
    // {
    //   component: "timeline_block",
    //   kicker: kickerPlaceholder,
    //   headline: "Timeline Block: " + headlinePlaceholder,
    //   subheadline: subheadlinePlaceholder,
    //   timeline_items: [
    //     {
    //       date: "28. Oktober 2022",
    //       image: { filename: "" },
    //       headline: headlinePlaceholder,
    //       text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
    //     },
    //     {
    //       date: "20.02.2023",
    //       image: imagePlaceholder,
    //       headline: headlinePlaceholder,
    //       text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
    //     },
    //   ],
    // },
    {
      component: "cards_block",
      kicker: kickerPlaceholder,
      headline: "Cards Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      view: "slider",
      card_items: [
        {
          image: imagePlaceholder,
          kicker: kickerPlaceholder,
          headline: headlinePlaceholder,
          text: "Eine wunderbare Heiterkeit hat meine ganze Seele eingenommen, gleich den süßen Frühlingsmorgen, die ich mit ganzem Herzen genieße.",
          link_text: "Mehr",
          link: "/",
        },
        {
          image: imagePlaceholder,
          kicker: kickerPlaceholder,
          headline: headlinePlaceholder,
          text: textPlaceholder,
          link_text: "Mehr",
          link: "/",
        },
        {
          image: imagePlaceholder,
          kicker: kickerPlaceholder,
          headline: headlinePlaceholder,
          text: textPlaceholder,
          link_text: "Mehr",
          link: "/",
        },
        {
          image: imagePlaceholder,
          kicker: kickerPlaceholder,
          headline: headlinePlaceholder,
          text: textPlaceholder,
          link_text: "Mehr",
          link: "/",
        },
        {
          image: imagePlaceholder,
          kicker: kickerPlaceholder,
          headline: headlinePlaceholder,
          text: textPlaceholder,
          link_text: "Mehr",
          link: "/",
        },
        {
          image: imagePlaceholder,
          kicker: kickerPlaceholder,
          headline: headlinePlaceholder,
          text: textPlaceholder,
          link_text: "Mehr",
          link: "/",
        },
        {
          kicker: kickerPlaceholder,
          headline: headlinePlaceholder,
          text: textPlaceholder,
          link_text: "Mehr",
          link: "/",
        },
      ],
    },
    {
      component: "content_nav_block",
      kicker: kickerPlaceholder,
      headline: "Content Nav Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      nav_items: [
        {
          image: imagePlaceholder,
          title: headlinePlaceholder,
          description: subheadlinePlaceholder,
          link: "/",
        },
        {
          image: imagePlaceholder,
          title: headlinePlaceholder,
          description: subheadlinePlaceholder,
          link: "/",
        },
        {
          title: headlinePlaceholder,
          description: subheadlinePlaceholder,
          link: "/",
        },
      ],
    },
    {
      component: "downloads_block",
      kicker: kickerPlaceholder,
      headline: "Downloads Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      download_items: [
        {
          title: "Ein PDF Download",
          description: decriptionPlaceholder,
          file: {
            filename: "/fake-pdf.pdf",
          },
        },
        {
          title: "Ein Bild Download",
          description: decriptionPlaceholder,
          file: {
            filename: "/fake-bild.jpg",
          },
        },
      ],
    },
    {
      component: "faq_block",
      kicker: kickerPlaceholder,
      headline: "FAQ Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      faq_items: [
        {
          title: "1. Frage",
          text: "1. Antwort",
        },
        {
          title: "2. Frage",
          text: "2. Antwort",
        },
      ],
    },
    {
      component: "features_block",
      kicker: kickerPlaceholder,
      headline: "Features Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      feature_items: [
        {
          component: "feature_item",
          icon: {
            type: "fas",
            icon: "fa-info",
          },
          title: headlinePlaceholder,
          text: textPlaceholder.split(".")[0],
        },
        {
          component: "feature_item",
          icon: {
            type: "fas",
            icon: "fa-info",
          },
          title: headlinePlaceholder,
          text: textPlaceholder.split(".")[0],
        },
        {
          component: "feature_item",
          icon: {
            type: "fas",
            icon: "fa-info",
          },
          title: headlinePlaceholder,
          text: textPlaceholder.split(".")[0],
        },
      ],
    },
    {
      component: "folder_block",
      kicker: kickerPlaceholder,
      headline: "Folder Block – Accordion View: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      folder_items: [
        {
          title: "1. Inhalt",
          content: [
            {
              component: "text_block",
              text: textPlaceholder,
            },
          ],
        },
        {
          title: "2. Inhalt",
          content: [
            {
              component: "text_block",
              text: textPlaceholder,
            },
          ],
        },
      ],
    },
    {
      component: "folder_block",
      kicker: kickerPlaceholder,
      headline: "Folder Block – Tabs View: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      view: "tabs",
      folder_items: [
        {
          title: "1. Inhalt",
          content: [
            {
              component: "text_block",
              text: textPlaceholder,
            },
          ],
        },
        {
          title: "2. Inhalt",
          content: [
            {
              component: "text_block",
              text: textPlaceholder,
            },
          ],
        },
      ],
    },
    {
      component: "logo_grid_block",
      kicker: kickerPlaceholder,
      headline: "Logo Grid Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      logo_items: [
        {
          link: "https://google.de",
          image: imagePlaceholder,
        },
        {
          link: "https://google.de",
          image: imagePlaceholder,
        },
        {
          image: imagePlaceholder,
        },
      ],
    },
    {
      component: "stats_block",
      kicker: kickerPlaceholder,
      headline: "Stats Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      stat_items: [
        {
          number: "14",
          unit: "Meter",
          title: headlinePlaceholder,
          text: textPlaceholder.split(".")[0],
        },
        {
          number: "35",
          unit: "Meter",
          title: headlinePlaceholder,
          text: textPlaceholder.split(".")[0],
        },
      ],
    },
    {
      component: "team_block",
      kicker: kickerPlaceholder,
      headline: "Team Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      team_items: [
        {
          name: "Max Mustermann",
          description: "Muster Position",
          tel: "123456789",
          fax: "987654321",
          email: "mm@musterfirma.de",
        },
        {
          name: "Max Mustermann",
          description: "Muster Position",
          tel: "123456789",
          fax: "987654321",
          email: "mm@musterfirma.de",
        },
        {
          name: "Max Mustermann",
          description: "Muster Position",
          tel: "123456789",
          fax: "987654321",
          email: "mm@musterfirma.de",
        },
      ],
    },
  ]

  return (
    <Layout>
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default ExtendedPage
